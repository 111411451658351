import { Holidays } from "../type";

interface PermissionsTooltipProps {
  tooltipTitle: string;
  tooltipDays: Holidays;
}

export const PermissionsTooltip: React.FC<PermissionsTooltipProps> = ({
  tooltipTitle,
  tooltipDays,
}) => {
  const containerStyle = {
    position: "absolute" as const,
    top: "90%",
    left: "0px",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    width: "300px",
    marginTop: "10px",
    zIndex: 999,
  };

  const headingStyle = {
    fontSize: "18px",
    color: "#5A5A5A",
    marginBottom: "16px",
    fontWeight: "600",
    textAlign: "center" as const,
  };

  const listStyle = {
    listStyle: "none",
    marginBottom: "16px",
    padding: 0,
  };

  const listItemStyle = {
    fontSize: "16px",
    color: "#838383",
    marginBottom: "8px",
  };

  const totalDaysStyle = {
    fontSize: "16px",
    color: "#1E1E1E",
  };

  const triangleStyle = {
    position: "absolute" as const,
    top: "-15px",
    left: "63%",
    transform: "translateX(-50%)",
    width: "0",
    height: "0",
    borderLeft: "15px solid transparent",
    borderRight: "15px solid transparent",
    borderBottom: "15px solid white",
    zIndex: 1000,
  };

  return (
    <>
      <div style={containerStyle}>
        <div style={triangleStyle}></div>

        <h2 style={headingStyle}>{tooltipTitle}</h2>
        <ul style={listStyle}>
          {tooltipDays.previousPeriodAccumulatedDays !== null && (
            <li style={listItemStyle}>
              Días acumulados período anterior:{" "}
              {tooltipDays.previousPeriodAccumulatedDays}
            </li>
          )}
          <li style={listItemStyle}>
            Días acumulados período actual:{" "}
            {tooltipDays.currentPeriodAccumulatedDays}
          </li>
          {tooltipDays.progressiveDays !== null && (
            <li style={listItemStyle}>
              Días progresivos: {tooltipDays.progressiveDays}
            </li>
          )}
          {tooltipDays.additionalDays !== null && (
            <li style={listItemStyle}>
              Días adicionales: {tooltipDays.additionalDays}
            </li>
          )}
          <li style={listItemStyle}>
            Días utilizados en período actual:{" "}
            {tooltipDays.currentPeriodUsedDays}
          </li>
          <li style={totalDaysStyle}>
            <strong>
              Total días disponibles: {tooltipDays.totalAvailableDays}
            </strong>
          </li>
        </ul>
      </div>
    </>
  );
};
