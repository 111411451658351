import { ReactElement, useCallback } from "react";
import {
  AuthenticationError,
  AxiosResult,
  AxiosResultDefaultError,
} from "../../../../api/request2";
import { createFetchContext } from "components/context/FetchContextFactory";
import Loading from "components/Loading";
import DisplayError from "components/info/DisplayError";
import { Holidays, Permissions, PermissionsResponse } from "../../type";
import { getData } from "views/Permissions/api";

interface BaseLoaderProps {
  children: (data: {
    holidays: Holidays;
    permissions: Permissions;
    registereds: {};
    requests: {};
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  PermissionsResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export function usePermissionsLoader() {
  return useFetch();
}

export const PermissionsLoaderConsumer = FetchConsumer;

export default function PermissionsLoader({ children }: BaseLoaderProps) {
  const request = useCallback(async (): Promise<
    AxiosResult<PermissionsResponse, AxiosResultDefaultError>
  > => {
    return getData();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ loading, data, error, refresh }) => {
          if (loading) {
            return <Loading />;
          } else if (error) {
            return (
              <DisplayError typeError={error.code} retryAction={refresh} />
            );
          } else if (data) {
            return children({
              holidays: {
                previousPeriodAccumulatedDays: data.holidaysStatus.p_acumulados,
                currentPeriodAccumulatedDays: data.holidaysStatus.p_periodo,
                progressiveDays: data.holidaysStatus.p_progresivo,
                additionalDays: data.holidaysStatus.p_transferidos,
                currentPeriodUsedDays: data.holidaysStatus.p_tomados_periodo,
                totalAvailableDays: data.holidaysStatus.p_disponibles,
                error: data.holidaysStatus.p_cod_error,
              },
              permissions: {
                totalAvailableDays: data.permissionsStatus.p_disponibles,
                error: data.permissionsStatus.p_cod_error,
              },
              registereds: data.registereds,
              requests: data.requests,
            });
          } else {
            return null;
          }
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
