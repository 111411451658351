// Components
import { CardLayout } from "components/CardLayout";
import { PermissionsSummary } from "./parts/PermissionsSummary";

//Store
import PermissionsLoader from "./parts/Loaders/PermissionsLoader";

const Permissions: React.FC = () => {
  return (
    <section id="solicitudes">
      <CardLayout>
        <PermissionsLoader>
          {(permissions) => (
            <div className="w-100">
              <div className="vacations-information">
                <div className="vacations-information-days">
                  <PermissionsSummary
                    description="Días de vacaciones periodo actual"
                    tooltipTitle="Resumen de vacaciones disponibles"
                    boxId="tooltip-vacaciones"
                    holidays={permissions.holidays}
                    total={permissions.holidays.totalAvailableDays}
                    code_error={permissions.holidays.error}
                  />
                  <PermissionsSummary
                    description="Días administrativos periodo actual"
                    tooltipTitle="Resumen de días administrativos disponibles"
                    boxId="tooltip-administrativos"
                    holidays={null}
                    total={permissions.permissions.totalAvailableDays}
                    code_error={permissions.permissions.error}
                  />
                </div>
              </div>
              <div>
                <h3 className="text-light fw-400 fs-20 mb-3">
                  Solicitudes de Vacaciones
                </h3>
              </div>
              <div>
                <h3 className="text-light fw-400 fs-20 mb-3">
                  Histórico de Vacaciones
                </h3>
              </div>
            </div>
          )}
        </PermissionsLoader>
      </CardLayout>
    </section>
  );
};

export default Permissions;
